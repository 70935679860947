body {
	background:#fff;
}

#logo-text {
  font-family: Tribeca;
  display:inline-block;
  vertical-align: top !important;
  margin-left: 10px;
  margin-top: -9px;
  letter-spacing: -5px;
}

a.border.nav-link.border-light.rounded.mr-1 {
  color: #3F729B;
}

a.border.nav-link.border-light.rounded.mr-1:hover {
  color: #1C2331;
}

.btn.btn-sm.indigo:hover{
  color: #fff;
}

.nav-link.active{
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-nav {
  align-items: center;
}

.nav-link button {
  background: #b68116 !important;
  font-family: Roboto, sans-serif !important;
}

#connect-button {
  background-color: transparent !important;
}

#categories > .col-md-4.mb-5{
  margin-bottom: 8rem !important;
}
.sectionPage {
  position: relative;
  top: -15px;
}

#home::after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  background: url('../assets/hero_pic.webp');
  background-position: center center;
  background-size: cover;
}

.edge-header {
  height: 75px;
}

#home.edge-header {
  height: 50vw;
}

#logoContainer {
  display:inline-block;
  padding: 0 5px 0 0;
  margin-top: 5px;
}

#logo {
  display: inline-block;
  background: url('../assets/logo.webp');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.free-bird {
  margin-top: 0 !important;
  position: absolute;
  left: 50%;
  transform: translate(-50%,0);
  top:10%;
}

.md-form {
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
}

body > div:nth-child(4) > div > div > div > div > div > div > div > div > div > div > div > div > a > div {
  display: none;
}

.accordion {
  margin: 40px 0 50px 0 !important;
}
.accordion-item .show {
  margin-bottom: 40px !important;
}
.accordion-body {
  margin-top: 40px !important
}

@media only screen and (max-width: 768px) {
  .free-bird {
    position: relative;
    top: 0;
    width: 100%;
  }
  .Ripple-parent img {
    width: 100%;
  }
}

