@font-face {
    font-family: 'Tribeca';
    src: local('Tribeca'), url(./assets/Tribeca.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

.accordion-header button {
    width: 100%;
}

.accordion-header button::after {
    display:inline-block;
    flex-shrink: 0;
    width: 1.8rem;
    height: 1.25rem;
    margin-left: 15px;
    content: "";
    background-image: url("./assets/down_arrow.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.accordion-header button.collapsed::after {
    background-image: url("./assets/down_arrow.png");
}

.accordion-header button:not(.collapsed)::after {
    background-image: url("./assets/up_arrow.png");
}
